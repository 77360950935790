function makeResizable(refs, opt = {}) {
    const elements =refs.value.length > 0  ? refs.value : [refs.value];
    elements.forEach(ref => {
        const el = ref;

        if (el.nodeName !== 'TABLE') {
            console.error(`table expected, got ${el.nodeName}!`);
            return;
        }

        const table = el;
        const thead = table.querySelector('thead');
        const temp = Array.from(thead.querySelectorAll('th'));
        const ths = temp.filter(th => !th.classList.contains('no-resizable'))

        const calcTableWidth = () => {
            let tableWidth = 0;
            let width = 0;

            ths.forEach((th) => {
                width = th.offsetWidth;
                tableWidth += width;
            }, 0);

            return tableWidth;
        };

        const applyTableWidth = () => {
            if (opt.fixedWidthTable) {
                table.style.width = calcTableWidth() + 'px';
                table.style.maxWidth = 'none';
            } else if (!table.style.width) {
                table.style.width = '100%';
                table.style.maxWidth = '9000px';
            }
        };

        const handleResize = e => {
            if (opt.resizable === false) return;
            activeTh.style.width = parseInt(activeTh.style.width) + e.movementX + 'px';
            table.style.width = parseInt(table.style.width) + e.movementX + 'px';
            e.stopPropagation();
            e.preventDefault();
        };

        let activeTh = null;
        let neighbourghTh = null;
        let resizing = false;
        table.style.position = 'relative';

        applyTableWidth();

        ths.forEach((th) => {
            if (!th.style.width) {
                th.style.width = th.offsetWidth + 'px';
            }

            th.originalWidth = th.style.width;
            const bar = document.createElement('div');

            bar.style.width = opt.handleWidth || '5px';
            bar.style.zIndex = opt.zIndex || 1;
            bar.className = opt.handleClass || 'columns-resize-bar';

            bar.addEventListener('click', e => e.stopPropagation());
            bar.addEventListener('mousedown', (e) => {
                if (e.target.parentElement.getAttribute('fixedsize')) {
                    return;
                }
                resizing = true;
                document.body.addEventListener('mousemove', handleResize);
                document.body.style.cursor = 'ew-resize';
                document.body.style.userSelect = 'none';

                activeTh = e.target.parentElement;
                neighbourghTh = activeTh.nextElementSibling;
                if (!neighbourghTh) {
                    neighbourghTh = activeTh.previousElementSibling;
                }

                if (!opt.fixedWidthTable) {
                    let tableWidth = calcTableWidth();
                    table.style.width = tableWidth + 'px';
                }
                e.stopPropagation();
                e.preventDefault();
            });

            th.appendChild(bar);
        });

        document.addEventListener('mouseup', (e) => {
            if (!resizing) return;
            resizing = false;
            document.body.removeEventListener('mousemove', handleResize);
            document.body.style.cursor = '';
            document.body.style.userSelect = '';
            if (typeof opt.afterResize === 'function') {
                opt.afterResize(ths);
            }
            e.stopPropagation();
            e.preventDefault();
        }, true);

        el.$resetColumnSizes = () => {
            ths.forEach((th) => {
                if(th.classList.contains('no-resizable')) return;
                th.style.width = th.originalWidth;
            }, 0);
            applyTableWidth();
        };
    });
}

export {
    makeResizable
}
