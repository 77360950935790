import _ from "lodash";
import { DateTime } from "luxon";

export function GroupedDinamicData(
  objetos = [],
  labels,
  valores,
  opIDPrincipal = 1,
  opIDAgreg = opIDPrincipal,
  tipo
) {
  const vTotal = valores.reduce((acumulador, valor, index) => {
    let operationFiltered = opIDPrincipal.filter((i) => i > 1);
    acumulador[valor.nome] = Number(
      realizarOperacao(objetos, valor.nome, operationFiltered[index]) // Removido o segundo parâmetro 'opIDPrincipal[index]'
    );
    return acumulador;
  }, {});

  for (let key in vTotal) {
    vTotal[key] /= 100;
  }
  //TABELA
  if (tipo === 1) {
    const Cartão = valores.reduce((acumulador, valor, index) => {
      acumulador[index] = Number(
        realizarOperacao(
          objetos,
          valor.nome,
          opIDPrincipal[index] === 1
            ? opIDPrincipal[index + 1]
            : opIDPrincipal[index],
          vTotal
        )
      );
      return acumulador;
    }, {});
    return Cartão;
  }

  let Tabela = _.chain(objetos)
    .groupBy((obj) =>
      labels.map((label) => convertDateToBR(obj[label.nome])).join("-")
    )
    .map((grupo) => {
      const vAgrupados = valores.reduce((acumulador, valor, index) => {
        let operationFiltered = opIDPrincipal.filter((i) => i > 1);
        acumulador[valor.titulo || valor.nome] = Number(
          realizarOperacao(grupo, valor.nome, operationFiltered[index], vTotal)
        );
        return acumulador;
      }, {})

      const vLabels = labels.reduce((acumulador, label) => {
        acumulador[label.titulo || label.nome] = convertDateToBR(
          grupo[0][label.nome]
        );
        return acumulador;
      }, {});
      return {
        ...vLabels,
        ...vAgrupados,
      };
    })
    .value();

  const valoresAgregadosTotal = valores.reduce((acumulador, valor, index) => {
    let operationFilteresd = opIDAgreg?.filter((i) => i > 1);
    acumulador[valor.titulo || valor.nome] = Number(
      realizarOperacao(
        objetos,
        valor.nome,
        operationFilteresd[index]
      )
    );
    return acumulador;
  }, {});
  Tabela.push(valoresAgregadosTotal);
  return Tabela;
}

function realizarOperacao(objetos, valor, operationId, vTotal) {
  let totals = vTotal || {};
  try {
    switch (operationId) {
      case 2: // Soma
        return _.sumBy(objetos, valor) || [];
      case 3: // Média
        return _.meanBy(objetos, valor) || [];
      case 4: // Valor Maximo
        return _.maxBy(objetos, valor)[valor] || [];
      case 5: // Valor Minimo
        return _.minBy(objetos, valor)[valor] || [];
      case 6: // Contagem
        return objetos?.length || [];
      case 7: // Contagem Distinta
        return _.uniqBy(objetos, valor).length || [];

      case 8: // Percentual
        return _.sumBy(objetos, valor) / (totals[valor] || 1);

      default:
        return [];
    }
  } catch (error) {
    console.log(error)
  }
}

export function backgroundColorWithOpacity(color, opacity, darkness = 0) {
  // Verifica se a cor é um CanvasGradient
  if (color instanceof CanvasGradient) {
    // Cria um canvas temporário
    const canvas = document.createElement("canvas");
    canvas.width = 1;
    canvas.height = 1;
    const ctx = canvas.getContext("2d");
    // Desenha um retângulo preenchido com o gradiente
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    // Lê a cor do retângulo
    const [r, g, b, a] = ctx.getImageData(0, 0, 1, 1).data;
    // Aplica a opacidade e a escuridão à cor
    const newR = Math.max(0, r - darkness);
    const newG = Math.max(0, g - darkness);
    const newB = Math.max(0, b - darkness);
    return `rgba(${newR}, ${newG}, ${newB}, ${(a / 255) * opacity})`;
  } else {
    // Se a cor não for um CanvasGradient, usa a lógica anterior
    let newColor;
    if (typeof color === "string" && color.startsWith("#")) {
      const [, r, g, b] = color.match(
        /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i
      );
      const newR = Math.max(0, parseInt(r, 16) - darkness);
      const newG = Math.max(0, parseInt(g, 16) - darkness);
      const newB = Math.max(0, parseInt(b, 16) - darkness);
      newColor = `rgba(${newR}, ${newG}, ${newB}, ${opacity})`;
    } else {
      const rgbaRegex = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/;
      const match = color.match(rgbaRegex);
      if (match) {
        const [, r, g, b, a = 1] = match;
        const newR = Math.max(0, parseInt(r) - darkness);
        const newG = Math.max(0, parseInt(g) - darkness);
        const newB = Math.max(0, parseInt(b) - darkness);
        newColor = `rgba(${newR}, ${newG}, ${newB}, ${a * opacity})`;
      } else {
        newColor = color;
      }
    }
    return newColor;
  }
}

export const limitadorEixo = {
  X: (type = 1) => {
    switch (type) {
      case 1:
        type = 0;
        break;
      case 6:
        type = 0;
        break;
      default:
        type = 5;
    }
    return type;
  },
  Y: (type = 2) => {
    switch (type) {
      case 1:
        type = 2;
        break;
      case 6:
        type = 12;
        break;
      default:
        type = 5;
    }
    return type;
  },
};

export function tipoGrafico(a) {
  switch (a) {
    case 1:
      a = "card";
      break;
    case 2:
      a = "bar";
      break;
    case 3:
      a = "bar";
      break;
    case 4:
      a = "doughnut";
      break;
    case 5:
      a = "line";
      break;
    case 6:
      a = "table";
      break;
    case 7:
      a = "bar";
      break;
    default:
      throw new Error(a, " inválido");
  }
  return a;
}

export function tipoCombo(a) {
  switch (a) {
    case 1:
      a = "line";
      break;
    case 2:
      a = "bar";
      break;
    default:
      null;
  }
  return a;
}

export function getGradient(ctx = [], chartArea = [], color, type) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  let gradient;
  let width;
  let height;
  if (Array.isArray(color)) {
    const gradients = color.map((singleColor) => {
      const singleGradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      );
      singleGradient.addColorStop(
        0,
        backgroundColorWithOpacity(singleColor, 1, 0)
      );
      singleGradient.addColorStop(
        0.2,
        backgroundColorWithOpacity(singleColor, 1, 5)
      );
      singleGradient.addColorStop(
        0.4,
        backgroundColorWithOpacity(singleColor, 1, 10)
      );
      singleGradient.addColorStop(
        0.6,
        backgroundColorWithOpacity(singleColor, 1, 15)
      );
      singleGradient.addColorStop(
        0.8,
        backgroundColorWithOpacity(singleColor, 1, 20)
      );
      singleGradient.addColorStop(
        1,
        backgroundColorWithOpacity(singleColor, 1, 25)
      );
      return singleGradient;
    });
    return gradients;
  } else {
    if (!gradient || width !== chartWidth || height !== chartHeight) {
      width = chartWidth;
      height = chartHeight;
      switch (type) {
        case 3:
          gradient = ctx.createLinearGradient(
            chartArea.left,
            0,
            chartArea.right,
            0
          );
          break;
        case 4:
          gradient = ctx.createLinearGradient(
            chartArea.left,
            0,
            chartArea.right,
            0
          );
          break;
        default:
          gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
      }
      gradient.addColorStop(0.2, backgroundColorWithOpacity(color, 1, 20));
      gradient.addColorStop(0.3, backgroundColorWithOpacity(color, 1, 15));
      gradient.addColorStop(0.4, backgroundColorWithOpacity(color, 1, 10));
      gradient.addColorStop(0.5, backgroundColorWithOpacity(color, 1, 5));
      gradient.addColorStop(0.6, backgroundColorWithOpacity(color, 1, -5));
      gradient.addColorStop(0.7, backgroundColorWithOpacity(color, 1, -10));
      gradient.addColorStop(0.8, backgroundColorWithOpacity(color, 1, -15));
      gradient.addColorStop(0.9, backgroundColorWithOpacity(color, 1, -20));
      return gradient;
    }
  }
  return gradient;
}

export function animatedColors(ctx, chartArea, color, type) {
  const gradient = getGradient(ctx, chartArea, color, type); // Sua função para obter o gradiente
  const gradientWithOpacity = backgroundColorWithOpacity(gradient, 1, 50); // Aplicar opacidade à cor de fundo
  return gradientWithOpacity;
}

export function convertDateToBR(value) {
  const regexDateTime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?$/;
  const regexDateTime2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}$/;
  const regexDate = /^\d{4}-\d{2}-\d{2}$/;
  const regexTime = /^\d{2}:\d{2}(:\d{2})?$/;
  let formattedValue;

  switch (true) {
    case regexDateTime.test(value):
    case regexDateTime2.test(value):
      formattedValue = DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm");
      break;
    case regexDate.test(value):
      formattedValue = DateTime.fromISO(value).toFormat("dd/MM/yyyy");
      break;
    case regexTime.test(value):
      formattedValue = DateTime.fromFormat(value, "HH:mm").toFormat("HH:mm");
      break;
    default:
      formattedValue = value;
  }
  return formattedValue;
}

export function convertDatetoISO(value) {
  const regexDateTime = /^\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}$/;
  const regexDate = /^\d{2}\/\d{2}\/\d{4}$/;
  //const regexDateMonth = /^\d{2}\/\d{4}$/;
  const regexTime = /^\d{2}:\d{2}(:\d{2})?$/;
  let formattedValue;
  switch (true) {
    case regexDateTime.test(value):
      formattedValue = DateTime.fromFormat(value, "dd/MM/yyyy HH:mm").toISO();
      break;
    case regexDate.test(value):
      formattedValue = DateTime.fromFormat(value, "dd/MM/yyyy").toISODate();
      break;
    case regexTime.test(value):
      formattedValue = DateTime.fromFormat(value, "HH:mm").toISOTime({
        includeOffset: false,
        suppressMilliseconds: true,
      });
      break;
    default:
      formattedValue = value;
  }
  return formattedValue;
}

export function dataSetFunction(data, params, values) {
  const dataSetArray = [];

  data.forEach(item => {
    const datasetParams = params.map(param => item?.[param]);
    const collectValues = values.map(value => item?.[value]);

    const newDataSet = {};
    values.forEach((value, index) => {
      if (datasetParams[index] !== undefined && collectValues[index] !== undefined) {
        newDataSet[datasetParams[index]] = collectValues[index];
      }
    });
    Object.keys(item).forEach(key => {
      newDataSet[key] = item[key];
    });
    dataSetArray.push(newDataSet);

  });

  return dataSetArray;
}

export function datasetStructure(v, obj, type, stateMode) {
  const setData = {
    label: v.titulo || v.nome,
    data: obj.data.slice(0, -1).map(a => a[v.titulo || v.nome]),
    backgroundColor: function (context) {
      if (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return
        }
        let cores = []
        chart.data.labels.forEach(() => {
          cores.push(getGradient(ctx, chartArea, v.cor, type))
        })
        return cores
      }
    },
    hoverBackgroundColor: backgroundColorWithOpacity(v.cor, 1, 50),

    borderWidth: (context) => {
      if (context) {
        let chart = context.chart;
        let widthb = []
        chart.data.labels.forEach(() => {
          if ((type == 7 && v.grafico_combo == 1) || type == 5) {
            widthb.push(1.5)
          } else {
            widthb.push(.8)
          }

        })
        return widthb
      }
    },
    borderColor: (context) => {
      if (context) {
        let chart = context.chart
        let color = []
        chart.data.labels.forEach(() => {
          if ((type == 7 && v.grafico_combo == 1) || type == 5) {
            stateMode == 'dark' ? color.push(backgroundColorWithOpacity(v.cor, 1, -50)) : color.push(backgroundColorWithOpacity(v.cor, 1, 50))
          } else {
            stateMode == 'dark' ? color.push(backgroundColorWithOpacity(v.cor, 1, -50)) : color.push(backgroundColorWithOpacity(v.cor, 1, 50))
          }

        })
        return color
      }
    },
    borderRadius: 4,
    datalabels: {
      display: function (context) {
        const maxLength = 15;
        const gap = Math.ceil(context.dataset.data.length / maxLength);
        return context.dataIndex % gap === 0 && v.rotulo;
      },
      font: {
        size: 9
      },
      borderRadius: 3,
      borderWidth: .6,
      borderColor: v.cor,
      padding: 1.7,
      backgroundColor:
        stateMode == 'light' ? backgroundColorWithOpacity(v.cor, .5, -60) : backgroundColorWithOpacity(v.cor, .5, 60),
      align: function (context) {
        var index = context.dataIndex;
        var curr = context.dataset.data[index];
        return curr >= 0 ? 'end' : 'start'
      },
      anchor: function (context) {
        var index = context.dataIndex;
        var curr = context.dataset.data[index];
        v.type
        return curr >= 0 ? 'end' : 'start'
      },
      clamp: true,
      color: stateMode == 'light' ? 'black' : 'white',

      formatter: (v, context) => {
        let label = v;
        let prefix = context.dataset.prefix || '';
        let suffix = context.dataset.suffix || '';
        let casadec = context.dataset.casadec;
        let value = label.toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec });
        return prefix + value + suffix;
      }
    },
    prefix: v.prefixo ? v.prefixo + ' ' : v.prefixo,
    suffix: v.sufixo ? ' ' + v.sufixo : v.sufixo,
    casadec: v.casadec,
    order: v.ordem_column,
    type: type == 7 ? tipoCombo(v.grafico_combo) : null,
    yAxisID: v.scala ? 'right' : 'left',
    hidden: false,
    stack: v.stack_group
  }
  return setData
}






export function generateRandomColors() {
  // Gerar componentes RGB aleatórios
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);

  // Converter para formato hexadecimal
  const corHexadecimal = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  return corHexadecimal;
}