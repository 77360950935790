import {
  tipoGrafico, GroupedDinamicData
  , backgroundColorWithOpacity, getGradient, dataSetFunction, datasetStructure, generateRandomColors
} from '@/components/configuracao_consulta/funcionalidadeschart';
import { ref } from 'vue'
const configurarChart = (card, stateMode) => {
  const interactionOptions = {
    3: { axis: 'y', intersect: false, mode: 'index' },
    5: { axis: 'y', intersect: false, mode: 'nearest' }
  }[card.tipo_grafico] || { axis: 'x', intersect: false, mode: 'nearest' };

  const pages = card?.configuracao_consulta.eixo_x.filter((_) => _.paginacao);
  const datasetsparams = card?.configuracao_consulta.eixo_y.filter((_) => _.datasetparam);
  const datasetsParamsUnique = (dados) => {
    const uniqueDatasets = datasetsparams.flatMap(dataset => {
      const newNames = [...new Set(dados.map(data => data[dataset.datasetparam]))];
      return newNames.map(name => ({
        ...dataset,
        nome: name,
        titulo: name,
        cor: generateRandomColors()
      }));
    });

    return uniqueDatasets;
  }
  let mydatasetsParamGlobal = []
  const pagesArrayUnique = pages.map(eixo =>
    [...new Set(card?.dados_consulta?.map(a => a[eixo?.paginacao]))]
  );
  const pagesCombinedFunction = (valores, indice = 0, combinacaoAtual = []) => {

    if (indice === valores.length) {
      return [combinacaoAtual];
    }

    const combine = [];

    for (let valor of valores[indice]) {
      const novaCombinacao = [...combinacaoAtual, valor];
      combine.push(...pagesCombinedFunction(valores, indice + 1, novaCombinacao));
    }

    return combine;
  };

  const forEachPageCombinedObject = pagesCombinedFunction(pagesArrayUnique)
  const chartGroupedByPages = forEachPageCombinedObject.map(valor => {
    const chartsDataCombination = card.dados_consulta?.filter(item => {

      for (let i = 0; i <= valor.length; i++) {
        if ((item[pages[i]?.paginacao] !== valor[i])) {
          return false;
        }
      }
      return true;
    })
    let dataChart = []
    let dataGrouped = {}

    dataGrouped
    if (datasetsparams.length) {
      const a1 = datasetsparams.map((_) => _.datasetparam)
      const a2 = card.configuracao_consulta.eixo_y.map(a => a.nome)
      dataChart = dataSetFunction(chartsDataCombination, a1, a2)
      mydatasetsParamGlobal.push(datasetsParamsUnique(chartsDataCombination))
      dataGrouped = {
        data: GroupedDinamicData(
          dataChart,
          card.configuracao_consulta.eixo_x.filter((_a, i) => i === 0),
          datasetsParamsUnique(chartsDataCombination),
          datasetsParamsUnique(chartsDataCombination).map(a => a.agregador))
        , paginacao: valor
      }
    } else {
      dataGrouped = {
        data: GroupedDinamicData(
          chartsDataCombination,
          card.configuracao_consulta.eixo_x.filter((_a, i) => i === 0),
          card.configuracao_consulta.eixo_y,
          card.configuracao_consulta.eixo_y.map(a => a.agregador))
        , paginacao: valor
      }
    }
    const s = {
      data: GroupedDinamicData(
        dataChart,
        card.configuracao_consulta.eixo_x.filter((_a, i) => i === 0),
        card.configuracao_consulta.eixo_y,
        card.configuracao_consulta.eixo_y.map(a => a.agregador))
      , paginacao: valor
    }
    s
    return dataGrouped
  })

  const Charts = chartGroupedByPages.map((obj, i) => {
    let datasetes = []
    if (datasetsparams.length) {
      mydatasetsParamGlobal[i].map(v => {
        datasetes.push(datasetStructure(v, obj, card.tipo_grafico, stateMode))
      })
    } else {
      card.configuracao_consulta.eixo_y.map((v) => {
        datasetes.push(datasetStructure(v, obj, card.tipo_grafico, stateMode))
      })
    }
    const mylabels = obj.data.slice(0, -1).map((g) => {
      const label = card.configuracao_consulta.eixo_x ?
        g[card.configuracao_consulta.eixo_x[0]?.titulo || card.configuracao_consulta.eixo_x[0]?.nome] : '';
      return { param: label, nome: card.configuracao_consulta.eixo_x[0]?.nome }
    });
    let delayed;
    const options = {
      animation: {
        onComplete: () => {
          delayed = true;
        },
        delay: (context) => {
          let lenth = context.dataset.data.length
          let delay = 0;
          if (context.mode === 'default' && !delayed) {
            delay = (context.dataIndex + 1) * (300 / lenth) + (context.datasetIndex + 1) * (100 / lenth);
          }
          delayed = false
          return delay;
        },
      },
      layout: {
        padding: {
          top: 10,
          bottom: 0
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      locale: 'pt-BR',
      interaction: interactionOptions,
      plugins: {
        legend: {
          labels: {
            boxHeight: 7,
            font: {
              size: 10
            },
            boxWidth: 12,
            color: stateMode && stateMode == 'light' ? '#494949' : '#C9C4C4'
          },
        },
        tooltip: {
          mode: 'index',
          enabled: true,
          callbacks: {
            label: function (context) {
              let label = context.parsed.y;
              let prefix = context.dataset.prefix || '';
              let suffix = context.dataset.suffix || '';
              let casadec = context.dataset.casadec;
              let datasetLabel = context.dataset.label;
              switch (card.tipo_grafico) {
                case 2: // Gráfico de Barra
                  label = context.parsed.y;
                  break;
                case 3: // Gráfico de Barra Vertical
                  label = context.parsed.x;
                  break;
                case 4: // Gráfico de Linha
                  label = context.parsed;
                  break;
                case 5: // Gráfico de Rosca
                  label = context.parsed.y;
                  break;
                default:
                  label;
                  break;
              }
              let value = label.toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec });
              return datasetLabel + ': ' + prefix + value + suffix;
            }
          }
        }
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          //stacked: true,
          ticks: {
            color: stateMode == 'light' ? '#494949' : '#C9C4C4',
            font: { size: 10 },

            padding: 3
          },
          grid: {
            borderColor: card.configuracao_consulta.cor_borda,
            color: stateMode == 'light' ? '#C4C4C4' : '#656565',
          }
        },

        right: {
          display: datasetes.some(a => a.yAxisID == 'right'),
          //stacked: true,
          position: 'right',
          ticks: {
            color: stateMode == 'light' ? '#494949' : '#C9C4C4',
            autoSkip: false,
            font: { size: 10 }
          },
          grid: {
            borderColor: card.configuracao_consulta.cor_borda,
            color: stateMode == 'light' ? '#C4C4C4' : '#656565',
            drawOnChartArea: false,
          }
        },
        left: {
          display: datasetes.some(a => a.yAxisID == 'left'),
          // stacked: true,
          position: 'left',
          ticks: {
            color: stateMode == 'light' ? '#494949' : '#C9C4C4',
            autoSkip: false,
            font: { size: 10 }
          },
          grid: {
            borderColor: card.configuracao_consulta.cor_borda,
            color: stateMode == 'light' ? '#C4C4C4' : '#656565'
          }
        },

      }
    };
    if (card.tipo_grafico === 3) {
      options.indexAxis = 'y';
      options.layout.padding.right = 60
    }
    const data = {
      labels: mylabels.map(a => a.param),
      nome: mylabels[0]?.nome,
      datasets: datasetes
    };
    const dataChart = {
      pag: obj.paginacao,
      referencia: ref(),
      data: data,
      options: options
    };
    return dataChart;
  })
  const myDataChart2 = {
    instance: ref(null),
    cor_borda: card.configuracao_consulta.cor_borda,
    titulo: card.titulo,
    colspan: card.configuracao_consulta.colspan,
    icone: card.configuracao_consulta.icone,
    rowspan: card.configuracao_consulta.rowspan,
    type: tipoGrafico(card.tipo_grafico),
    id_type: card.tipo_grafico,
    paginacao: forEachPageCombinedObject,
    mydata: Charts,
    id: card.id_consulta_painel,
    id_consulta: card.id_consulta,
    state: stateMode,
    data_loaded: card.data_loaded
  }


  return myDataChart2
}

const configurarCard = (card, stateMode) => {
  const cardData = GroupedDinamicData(
    card.dados_consulta,
    null,
    card.configuracao_consulta.valor,
    card.configuracao_consulta.valor.map(a => a.agregador),
    card.configuracao_consulta.valor.map(a => a.agregador),
    card.tipo_grafico)
  const valor = card.configuracao_consulta.valor.map((v) => {

    return {
      label: v.titulo || v.nome,
      valor: Object.values(cardData)[0],
      agregador: v.agregador,
      backgroundColor: v.cor,
      prefix: v.prefixo ? v.prefixo + '' : v.prefixo,
      suffix: v.sufixo ? '' + v.sufixo : v.sufixo,
      casadec: v.casadec,
      valorAtual: Object.values(cardData)[1],
      rotulos: v.rotulo
    }
  })
  const cardStructure = {
    valores: valor,
    colspan: card.configuracao_consulta.colspan,
    rowspan: card.configuracao_consulta.rowspan,
    id_type: card.tipo_grafico,
    icone: card.configuracao_consulta.icone,
    cor_borda: card.configuracao_consulta.cor_borda,
    titulo: card.titulo,
    id: card.id_consulta_painel,
    state: stateMode
  }
  if (valor.length > 1) {

    const options = {
      layout: {
        padding: {
          right: 30,
          left: 30
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      locale: 'pt-BR',
      plugins: {
        scales: {
          x: {
            ticks: {
              color: stateMode == 'light' ? 'black' : 'white'
            }
          },
          y: {
            ticks: {
              color: stateMode == 'light' ? 'black' : 'white'
            }
          }
        },
        tooltip: {

        },
        legend: {
          display: false,
        },
      },
      rotation: 270,
      cutout: '75%',
    }
    const datasets =
    {
      labels: valor.map(a => a.label),
      datasets: [{
        label: valor.map(a => a.label),
        data: Object.values(cardData),
        backgroundColor:
          function (context) {
            const chart = context.chart;
            const { ctx, chartArea } = chart;
            if (!chartArea) {
              return
            }
            const cor =
              valor.map((a, i) => i > 0 ?
                backgroundColorWithOpacity(a.backgroundColor, .7) :
                getGradient(ctx, chartArea, a.backgroundColor, card.tipo_grafico))
            return cor
          }
        ,
        borderWidth: 0,
        prefix: valor.map(a => a.prefix),
        suffix: valor.map(a => a.suffix),
        casadec: valor.map(a => a.casadec),
        rotulos: valor.map(a => a.rotulos),
        datalabels: {
          display: (vas) => {
            const index = vas.dataIndex
            const boolean = vas.dataset.rotulos[index]
            return boolean
          },
          align: 'start',
          borderRadius: 12,
          labels: {
            title: {
              font: {
                family: 'sans-serif',
                size: 11
              },
              color: stateMode == 'light' ? 'black' : 'white',
              clamp: true,
              align: 'start',
              anchor: 'start',
              backgroundColor: '',
              formatter: (_value, context) => {
                const index = context.dataIndex;
                const casadec = context.dataset.casadec[index];
                const prefix = context.dataset.prefix[index] || '';
                const suffix = context.dataset.suffix[index] || '';
                const data = (prefix + ' ') + context.chart.data.datasets[context.datasetIndex].data[index].toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec }) + (' ' + suffix)

                return data;
              }
            },
            value: {
              display: false,
              align: 'center',
              color: stateMode == 'light' ? 'black' : 'white',
              font: {
                size: 11,
                family: 'sans-serif'
              },
              anchor: 'start',
              formatter: (_val, cont) => {
                const index = cont.dataIndex;
                const casadec = cont.dataset.casadec[index];
                const prefix = cont.dataset.prefix[index] || '';
                const suffix = cont.dataset.suffix[index] || '';
                const value = cont.dataset.data[index];

                const teste =
                  (prefix + ' ') +
                  value.
                    toLocaleString('pt-BR', { minimumFractionDigits: casadec, maximumFractionDigits: casadec })
                  + (' ' + suffix);

                return teste
              }
            }
          },
        },
        circumference: 180,
      }]
    }

    const d = {
      data: datasets,
      options: options,
      type: 'doughnut',
      ref: null,
      data_loaded: card.data_loaded
    }
    cardStructure.acelerometro = d
  }

  return cardStructure
}

const configurarTable = (card, stateMode) => {
  const columns = card.configuracao_consulta.col.sort((a, b) => a.ordem_column - b.ordem_column);
  const tableCardData = GroupedDinamicData(
    card.dados_consulta,
    columns.filter(o => o.agregador_total == 1),
    columns.filter(o => o.agregador_total > 1),
    columns.map(a => a.agregador),
    columns.map(a => a.agregador_total),
    card.tipo_grafico)

  const dataTable = {
    id_type: card.tipo_grafico,
    titulo: card.titulo,
    id: card.id_consulta_painel,
    id_consulta: card.id_consulta,
    colspan: card.configuracao_consulta.colspan,
    cor_borda: card.configuracao_consulta.cor_borda,
    icone: card.configuracao_consulta.icone,
    dados: tableCardData,
    dadosOriginais: card.dados_consulta,
    colunas: columns,
    state: stateMode,
    filterData: [],
    data_loaded: card.data_loaded
  };
  return dataTable
}
const setGrafico = (card, stateMode) => {

  switch (card?.tipo_grafico) {
    case 1:
      return configurarCard(card, stateMode);
    case 6:
      return configurarTable(card, stateMode);
    case 2:
    case 3:
    case 4:
    case 5:
    case 7:
      return configurarChart(card, stateMode);
    default:
      return [];
  }
}

export {
  setGrafico
}